import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuGroupColumn = lazy(
  () => import("./menu-group-column-dT4tQ6di.js").then((module) => ({
    default: module.MenuGroupColumn
  }))
);
function MenuGroupColumn({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuGroupColumn, { ...props });
}
const useMenuGroupColumnContent = () => {
  return useMemo(
    () => ({
      menuGroupColumn: {
        component: MenuGroupColumn
      }
    }),
    []
  );
};
export {
  LazyMenuGroupColumn as MenuGroupColumn,
  useMenuGroupColumnContent
};
